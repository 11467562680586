import React, { useEffect, useState } from "react";
import BookingSession from "../../Components/BookingSession/BookingSession";
import { useParams } from "react-router-dom";
import { open_api, getCountryCode } from "../../Utils/network";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "../../Components/Dialog/LoadingOverlay";
import FAQ from "../../Components/Faq/FAQ";
import styles from "./BookingSessionPage.module.css";
import BookingExpertDetails from "../../Components/BookingSession/BookingExpertDetails";
import BookingExpertAbout from "../../Components/BookingSession/BookingExpertAbout";
import CoachingCard from "../../Components/Card/CoachingCard";
import notAvailable from "../../Assets/Logo_icons/not_available.svg";
import AppPromotion from "../../Components/Card/AppPromotion";
import { Helmet } from "react-helmet";
import { WhatsApp } from "@mui/icons-material";
import WhatsAppButton from "../../Components/Button/WhatsAppButton";

const BookingSessionPage = () => {
  const [expertDetails, setExpertsDetails] = useState(null);
  const [packages, setPackages] = useState(null); // Set initial state to null to track loading state
  const [loading, setLoading] = useState(true); // Loading state to track data fetching
  const { expertslug } = useParams();
  const location = useLocation();
  const [countryCode, setCountryCode] = useState("in");

  useEffect(() => {
    const fetchCountry = async () => {
      const countryCode = await getCountryCode();
      setCountryCode(countryCode);
    };
    fetchCountry();
  }, []);

  // Destructure location.state with default values
  const {
    bypassPurchase = false,
    bypassPackage = false,
    bookedPackageId = null,
    purchaseId = null,
    purchasedUserId = null,
    duration = null,
    isDataRequired = false,
    remainingSession = null,
  } = location.state || {};

  //console.log(location.state);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Fetch expert details and packages
  useEffect(() => {
    const getExpertDetails = async () => {
      try {
        const res = await open_api.get(`profiles/experts/${expertslug}/`);
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setExpertsDetails(res?.data);

          // Fetch packages after expert details are available
          const packageRes = await open_api.get(
            `/session/package_list?expert=${res?.data.id}`
          );
          setPackages(packageRes?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching expert details:", error);
      } finally {
        setLoading(false); // Stop loading when both expert details and packages are fetched
      }
    };

    getExpertDetails();
  }, [expertslug]);

  // Render loading spinner or message if data is being fetched
  if (loading) {
    return <LoadingOverlay />;
  }

  const faqs = [
    {
      id: 1,
      title: "How can I join the meeting once booked?",
      answer: [
        {
          id: 1,
          answer:
            "Once you have booked, you can download the MyBurgo app and access the meeting link under 'My Learning,' or you can find the link on the website in your profile under 'Upcoming Sessions.' We will also send you an email with the link to join the meeting, along with a reminder email 60 minutes before the actual call. For support, contact us at hello@myburgo.com or call +91 7710048212.",
        },
      ],
    },
    {
      id: 2,
      title: "Can I cancel a booking",
      answer: [
        {
          id: 1,
          answer:
            "No, you cannot cacel a booking, For support contact hello@myburgo.com or give a call to +91 7710048212",
        },
      ],
    },
    {
      id: 3,
      title: "Can I reschedule a booking",
      answer: [
        {
          id: 1,
          answer:
            "Currently we dont support rescheduling a booking. however if you wish to reschedule a booking you can reach out to hello@myburgo.com or +91 7710048212.",
        },
      ],
    },
    // Add more FAQ items here
  ];

  const priceSymbol = countryCode === "in" ? "₹" : "$";
  // Once data is fetched, render the BookingSession component
  return (
    // <div>
    //   <BookingSession
    //     expertDetails={expertDetails}
    //     packages={packages}
    //     bypassPackage={bypassPackage}
    //     bypassPurchase={bypassPurchase}
    //     bookedPackageId={bookedPackageId}
    //     purchaseId={purchaseId}
    //     purchasedUserId={purchasedUserId}
    //     duration={duration}
    //     isDataRequired={isDataRequired}
    //     remainingSession={remainingSession}
    //   />
    //   <div className="section-container">
    //     <FAQ data={faqs} />
    //   </div>
    // </div>
    <div>
      <Helmet>
        <title>MyBurgo - Parenting App | Pregnancy App | SelfCare App</title>
        <meta
          name="description"
          content="MyBurgo is India's comprehensive parenting app offering expert guidance, interactive workshops, personalized resources, and community support for parents and pregnant women. Empower your parenting journey with child development tools, curated recipes, mental health support, and more."
        />
      </Helmet>

      <div className={styles.bookingSessionPage}>
        <BookingExpertDetails expert={expertDetails} />
        <div className={styles.card}>
          <div
            className={`${styles.contentContainer} ${
              !expertDetails.about_session_offered ? styles.centerAligned : ""
            }`}
          >
            {expertDetails.about_session_offered != "" ? (
              <>
                <div className={styles.leftSection}>
                  <BookingExpertAbout about={expertDetails} isPackage={false} />
                </div>
                <div className={styles.divider}></div>
              </>
            ) : null}

            <div className={styles.rightSection}>
              {packages.length === 0 ? (
                <div className={styles.notAvailableContainer}>
                  <img
                    src={notAvailable}
                    alt="No Slots Available"
                    style={{ width: "50%", height: "50%" }}
                  />
                  <div className={styles.noSlotsMessage}>
                    This expert is not offering one-on-one sessions right now.
                  </div>
                  <div className={styles.noSlotsMessageSubtitle}>
                    We can help you to connect with this expert. Leave a message
                    on WhatsApp at +91 7710048212.
                  </div>
                  <div>
                    <WhatsAppButton isChatOnWhatsapp={true} />
                  </div>
                </div>
              ) : (
                packages.map((pkg, index) => (
                  <CoachingCard
                    key={index}
                    rating={5}
                    tag={index === 2 ? "Best seller" : ""}
                    expertSlug={expertDetails.slug}
                    title={pkg.title}
                    subtitle={pkg?.about_package}
                    duration={pkg.slot_duration.duration_name}
                    sessionType={"Video Meeting"}
                    priceSymbol={priceSymbol}
                    price={
                      countryCode === "in"
                        ? pkg.selling_price
                        : pkg.selling_price_usd
                    }
                    originalPrice={countryCode === "in" ? pkg.mrp : pkg.mrp_usd}
                    packageId={pkg.id}
                    discount={
                      countryCode === "in"
                        ? pkg.discount_percentage_inr
                        : pkg.discount_percentage_usd
                    }
                    sessionCount={pkg.sessions_count}
                  />
                ))
              )}
            </div>
          </div>

          <div className={styles.sectionContainer}>
            <h2 className={styles.CRHeading}>Frequently asked questions</h2>
            <FAQ data={faqs} />
          </div>
          <AppPromotion />
        </div>
      </div>
    </div>
  );
};

export default BookingSessionPage;
