import { formatDate } from "../../Utils/helper"
import "./EventPage.css"
import BookSessionDialog from "../../Components/Dialog/BookSessionDialog"
import FAQ from "../../Components/Faq/FAQ"
import { useState, useEffect, useRef } from "react"
import { open_api_without_auth } from "../../Utils/network"
import EventBookingDialog from "../../Components/Dialog/EventBookingDialog"


export default function EventPage() {
  const [events, setEvents] = useState([]);
  const [brand, setBrands] = useState([]);
  const [expect, setExpect] = useState([]);
  const [terms_and_conditions, setTandC] = useState("");
  const [faq, setFAQ] = useState([]);
  const [loading, setLoading] = useState(true);
  const [quantity,setQuantity] = useState(1);
  const [showTicket,setShowTicket] = useState(false)
  const [dialogPosition, setDialogPosition] = useState({ top: 0, left: 0 });
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current index for circular scroll

  const getEventData = async () => {
    const response = await open_api_without_auth.get("/events/details/nutrifeista-jan-2025");
    setEvents(response.data);
    setBrands(response.data.event_brands);
    setExpect(response.data.event_what_to_expect);
    setTandC(response.data.terms_and_conditions[0]);
    setFAQ(response.data.faqs);
    setLoading(false);
  }



  const counter = (count)=>{
    setQuantity(count);
  }

  useEffect(() => {
    getEventData();
    console.log(events)
  }, []);

  const getTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(hours, minutes);

    const hours12 = date.getHours() % 12 || 12;
    const period = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${hours12}:${minutes} ${period}`;
  }

  const handleTicket = (event)=>{
     setShowTicket(true);
  }

  const containerRef = useRef(null);

  const moveContent = (direction) => {
    setExpect((prevExpect) => {
      let newExpect;
      if (direction === "left") {
        const lastElement = prevExpect[prevExpect.length - 1];
        newExpect = [lastElement, ...prevExpect.slice(0, -1)];
      } else if (direction === "right") {
        const firstElement = prevExpect[0];
        newExpect = [...prevExpect.slice(1), firstElement];
      }
      return newExpect;
    });
  };


  const style = {
    transform: `translateX(-${currentIndex * 300}px)`,
    transition: 'transform 0.5s ease',
  };
  
  const onClose= ()=>{
    setShowTicket(false)
  }

  return (
    <div className={`Event-page ${showTicket?"overlay":""}`}>
      <section className="event-container">
        <div className="image-holder">
          <img className="event-img" src={events.event_image} />
        </div>
        <div className="content-wrapper">
          <div className="content-div">
            <p className="pop-600 fs-32">{events.title}</p>
            <h3 className="fs-24">{events.event_location}</h3>
            <div>
              <p className="text-italic">When?</p>
              {events.event_is_single_day ? (
                <>
                  <span className="text-highlight">{formatDate(events.event_start_date)}</span> | {getTime(events.event_start_time)} to {getTime(events.event_end_time)}
                </>
              ) : (
                <>
                  <span className="text-highlight">{formatDate(events.event_start_date)}</span> - {events.event_end_date}
                </>
              )}
            </div>
            <div>
              <p className="text-italic">Where?</p>
              <p className="text-highlight">{events.event_location}</p>
            </div>
            <div className="booking-btn-div">
              <button className="booking-btn sm-btn" onClick={handleTicket}>
                Book Your Tickets
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="brand-carousal">
        <h2 className="blue-header">More than {brand.length - 1}+ brands</h2>
        <div className="wrapper-container">
          <div className="brand-carousel-track">
            {brand.length > 0 &&
              brand.map((data, index) => (
                <img
                  key={index}
                  src={data.brand_image}
                  className="brand-img"
                  alt={`Brand ${index + 1}`}
                />
              ))}
          </div>
          <div className="brand-carousel-track">
            {brand.length > 0 &&
              brand.map((data, index) => (
                <img
                  key={index}
                  src={data.brand_image}
                  className="brand-img"
                  alt={`Brand ${index + 1}`}
                />
              ))}
          </div>
          <div className="brand-carousel-track">
            {brand.length > 0 &&
              brand.map((data, index) => (
                <img
                  key={index}
                  src={data.brand_image}
                  className="brand-img"
                  alt={`Brand ${index + 1}`}
                />
              ))}
          </div>
         
          
        </div>
      </section>

      <section className="event-details">
        <div className="event-desc">
          <h2 className="blue-header">About the event</h2>
          <p className="event-content">{events.description}</p>
        </div>
        <div className="map-direction">
          <iframe
            src={events.event_map_link}
            width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="origin"
          ></iframe>
          <button className="btn-direction">
            <a href={events.event_directions_link} className="btn-direction" target="blank">Get Direction</a>
          </button>
        </div>
      </section>
      <section className="book-ticket-btn">
        <button className="booking-btn" onClick={handleTicket}>
          Book Your Tickets Now
        </button>
      </section>
      <section className="what-to-expect-section">
        <h2 className="blue-header">What to Expect</h2>
        <div className="wrapper-container">
          <button className="nav-btn left" onClick={() => moveContent('left')}>&lt;</button>
          
          <div className="what-to-expect-container" ref={containerRef} style={style}>
          {expect.map((data, index) => (
          <div key={index} className="container-img">
            <img className="img-style" src={data.what_to_expect_image} alt={data.title} />
            <section className="title-desc">
              <h2 className="title-style">{data.title}</h2>
              <p className="description">{data.description}</p>
            </section>
          </div>
           ))}
          </div>
          <button className="nav-btn right" onClick={() => moveContent('right')}>&gt;</button>
        </div>
      </section>

      <section className="faq">
        <h2 className="blue-header">Frequently Asked Questions</h2>
        <FAQ data={faq} />
      </section>
      <section className="tandc">
        <h2 className="blue-header">Terms and Condition</h2>
        <div className="points" dangerouslySetInnerHTML={{ __html: terms_and_conditions.terms_and_conditions }} />
      </section>
      
      {showTicket && <EventBookingDialog
       image={events.event_image}
       details={events}
       counter={counter}
       count={quantity}
       onClose={onClose}
       isEvent={true}
      />}
      
    </div>
  );
}
