import React from "react";
import "./WhatsAppButton.css"; // Import CSS file
import whatsappLogo from "../../Assets/Logo_icons/whatsappimage.png"; // Import your logo image

const WhatsAppButton = ({
  groupLink,
  text = "Join the WhatsApp group",
  isChatOnWhatsapp,
}) => {
  // WhatsApp chat link (for direct chat if isChatOnWhatsapp is true)
  const chatLink = `https://api.whatsapp.com/send?phone=917710048212&text=Hi%20can%20you%20please%20help%20me%20with%20...`;

  return (
    <a
      href={isChatOnWhatsapp ? chatLink : groupLink}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
    >
      <img src={whatsappLogo} alt="WhatsApp Logo" className="whatsapp-logo" />
      {isChatOnWhatsapp ? "Chat on WhatsApp" : text}
    </a>
  );
};

export default WhatsAppButton;
