import styles from "./EventBookingDialog.module.css"
import ticket from "../../Assets/ticket.svg"
import { formatDate } from "../../Utils/helper"
import { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { open_api_with_user_token, isProduction } from "../../Utils/network";
import LoadingOverlay from "./LoadingOverlay";
import successIcon from "../../Assets/feedback/successIcon.svg";
import FailedIcon from "../../Assets/feedback/failedIcon.svg";
import {
    Dialog as MuiDialog,
    DialogContent,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import {
    DialogContainer,
    LeftSection,
    RightSection,
    Card,
    Button,
    OtpContainer,
    ServiceCard,
    AmountCard,
    OtpInputContainer,
    ResendOtpLink,
    SuccessMessage,
    Label,
    InputField,
    PayButton,
    ErrorText,
    CouponContainer,
    CouponInput,
    ApplyButton,
    Select,
} from "./PaymentDialogStyles";
import ConfirmationDialog from "./ConfirmationDialog";
import increase from "../../Assets/increase.svg"
import decrease from "../../Assets/decrease.svg"
import tick from "../../Assets/tick.svg"
import close from "../../Assets/close.svg"
import LoginCard from "../Login/LoginCard";
import useAuth from "../Hook/useAuth";
import CongratulationsBanner from "../SuccessCard/CongratulationsBanner";
import ConfettiExplosionComponent from "../Coupon/ConfettiExplosionComponent";
import ConfettiExplosion from "react-confetti-explosion";
import {
    fetchCountryCode,
    validatePhoneNumber,
    validateEmail,
    handleOTPChange,
    handleKeyDown,
    handlePhoneNumberChange,
    handleEmailChange,
    applyCoupon,
    requestOTP,
    validateOTP,
    addAlert,
    removeAlert,
    fetchProfileTypes,
    handleCouponChange,
    checkUserInfoExists,
    fetchUserDetails,
    postExpertUserPurchase,
    validateGoogleLoginWithToken,
} from "./helper";

export default function EventBookingDialog({ image, details, counter, count, onClose, isEvent }) {
    const [isCompleted, setIsCompleted] = useState(false)
    const [enablePayButton, setEnablePayButton] = useState(false);
    const [isUserInfoExists, setIsUserInfoExists] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [bearerLocalToken, setBearerToken] = useState("");
    const [useEmail, setUseEmail] = useState(false);
    const [usePhone, setUsePhone] = useState(false);
    const [contactValue, setContactValue] = useState("");
    const [isVerified, setVerified] = useState(false);
    const [otpRequested, setOtpRequested] = useState(false);
    const [otp, setOtp] = useState(Array(4).fill(""));
    const [otpValidated, setOtpValidated] = useState(false);
    const [firstName, setfirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogProps, setDialogProps] = useState({});
    const [firstNameError, setfirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [countryCode, setCountryCode] = useState("in");
    const inputRefs = useRef([]);
    const [coupon, setCoupon] = useState("");
    const [discountAmount, setDiscountAmount] = useState(details.event_mrp_price - details.event_selling_price);
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [couponFound, setCouponFound] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [newSellingPrice, setNewSellingPrice] = useState(0);
    const [matchingCoupon, setMatchingCoupon] = useState({});
    const [profileType, setProfileType] = useState("");
    const [profileTypeError, setProfileTypeError] = useState(false);
    const {
        isAuthenticated,
        userName,
        userId,
        bearerToken,
        setIsAuthenticated,
        setUserId,
        setUserName,
    } = useAuth();
    // const [isUserInfoExists, setIsUserInfoExists] = useState(false);
    const [expertDetailsList, setExpertDetailsList] = useState([]);
    const [message, setMessage] = useState("");
    // const [enablePayButton, setEnablePayButton] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false); // For showing confetti
    const [popupCoupon, setPopupCoupon] = useState(""); // For popup message
    const [open, setOpen] = useState(false)
    const priceSymbol = countryCode === "in" ? "₹" : "$";
    const isUSD = countryCode === "in" ? "INR" : "USD";


    const handleApplyCoupon = async () => {
        var couponToApply = coupon;
        const result = await applyCoupon(
            coupon,
            null,
            null,
            bearerToken,
            details.selling_price,
            details.event_mrp_price,
            setShowAlert,
            handleAddAlert,
            setCouponFound,
            setMatchingCoupon,
            setNewSellingPrice,
            setDiscountAmount,
            setCouponDiscount,
            setCoupon,
            isUSD
        );

        if (result.success) {
            setCouponFound(true);
            setShowConfetti(true);
            setPopupCoupon(couponToApply);
            setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
        }
    };
    const handlePayEnable = (enable) => {
        setEnablePayButton(enable);
    };

    const handleCloseConfirmationDialog = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setConfirmationDialogOpen(false);
            resetAllStates();
        }
    };

    const resetAllStates = () => {
        onClose();
        counter(1);
    }

    const handleAddAlert = (title, message, type, autoClose = false) => {
        addAlert(title, message, type, autoClose, setAlerts);
    };
    const handleUserInfoExists = (exists) => {
        setIsUserInfoExists(exists);
    };

    const handleValidationComplete = () => {
        setShouldRedirect(true);
    };

    const handleBearerToken = (token) => {
        setBearerToken(token);
    };

    const handleClick = (event) => {
        console.log(event)
        if (event.target.id === '2') {
            counter(count + 1)
        }
        else if (event.target.id === '1' && count > 1) {
            counter(count - 1)
        }

    }

    const getEventDetails = async () => {
        const localData = {
            event_id: 1,
            currency: "INR",
            purchasedFrom: 1,
            quantity: count
        };

        try {
            const res = await open_api_with_user_token(bearerToken).post(
                `/events/session/card/`,
                localData
            );
            if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
                return res?.data;
            }
        } catch (err) {
            //console.log(err);
        }
    };

    const getEventPayData = async () => {
        const eventDetails = await getEventDetails();
        console.log(eventDetails);
        const eventPurchaseId = eventDetails.id ?? 0;
        const appliedCoupon = 100;
        const localData = {
            event_purchase_id: eventPurchaseId,
            amount: eventDetails.total_pay,
        };
        try {
            const res = await open_api_with_user_token(bearerToken).post(
                `/payment/pay/`,
                localData
            );
            if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
                return { paymentData: res?.data, appliedCoupon, eventPurchaseId };
            }
        } catch (err) {
            //console.log(err);
        }
        
    }

    const handlePaymentFailure = async (response, cart_id) => {
        try {
            const res = await open_api_with_user_token(bearerToken).patch(
                `/payment/pay/${cart_id}/`
            );
            if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
                console.error("Payment failed:", response.error);
                setPaymentSuccessful(true);
                setDialogProps({
                    status: "failure",
                    statusText: "Payment Failed",
                    statusDescription:
                        "There was an issue with the payment. Please try again or contact support at hello@myburgo.com if the problem persists.",
                    statusIcon: FailedIcon,
                    backgroundColor: "#FFFF",
                });
                setIsLoading(false);
                setConfirmationDialogOpen(true);
            }
        } catch (err) {
            //console.log(err);
        } finally {
            setIsLoading(false); // End loading
            counter(1)
        }
    };

    const handlePaymentSuccess = async (cart_id, workshopPurchaseId) => {
        try {
            const localData = {
                is_bypassed: false,
            };
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const res = await open_api_with_user_token(bearerToken).patch(
                `/payment/pay/${cart_id}/`,
                localData
            );
            if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
                const sessionData = sessionStorage.getItem("expertSession");
                if (sessionData) {
                    const expertSessionData = JSON.parse(sessionData);
                    postExpertUserPurchase(
                        expertSessionData,
                        bearerToken,
                        workshopPurchaseId,
                        null
                    );
                }
                setPaymentSuccessful(true);
                setIsLoading(false);
                setDialogProps({
                    status: "success",
                    statusText: "Thank you",
                    statusDescription: `Your purchase of the workshop title is confirmed! 🎉`,
                    statusIcon: successIcon,
                    backgroundColor: "#FFFF",
                });
                setConfirmationDialogOpen(true);
            }
        } catch (err) {
            //console.log(err);
        } finally {
            setIsLoading(false); // End loading
            counter(1)
        }
    };

    const initiateRazorpayPayment = async (
        paymentData,
        appliedCoupon,
        eventPurchaseId
    ) => {
        console.log(paymentData)
        const title = ""
        const description = `Payment for ${title}`;
        setMessage("Confirming your order, please don't close the screen...");
        const razorpayKey = isProduction
            ? "rzp_live_0MAqWVbokRW0Uu"
            : "rzp_test_41tt7Y8uKXr9rl";
        const options = {
            key: razorpayKey,
            amount: paymentData.amount * 100, // Razorpay works with paise, so multiply by 100
            currency: isUSD ? "USD" : "INR",
            name: "myburgo",
            description: description,
            order_id: paymentData.order_id,
            timeout: 600,
            handler: (response) => {
                handlePaymentSuccess(paymentData.id, eventPurchaseId);
            },
            prefill: {
                name: `${firstName} ${lastName}`,
                email: "appliedCoupon.user_detail.email",
                contact: "appliedCoupon.user_detail.phone",
            },
            notes: {
                address: "website",
            },
            theme: {
                color: "#7A53FF",
            },
            modal: {
                ondismiss: function () {
                    handlePaymentFailure(
                        { error: "Payment window closed without completing the payment." },
                        paymentData.id
                    );
                },
            },
        };
        const rzp = new window.Razorpay(options);
        console.log(options)
        rzp.on("payment.failed", ({ response }) => {
            console.error("Payment failed:", response.error);
        });

        rzp.open();
    };

    const goNext = () => {
        setIsCompleted(true);
    }
    const goBack = () => {
        setIsCompleted(false);
    }

    const handlePayment = async () => {
        console.log("handlong")
        setIsLoading(true);
        try {
            if (isUserInfoExists) {
                console.log("if")
                const { paymentData, appliedCoupon, eventPurchaseId } = await getEventPayData();
                console.log(paymentData, appliedCoupon, eventPurchaseId)
                await initiateRazorpayPayment(
                    paymentData,
                    appliedCoupon,
                    eventPurchaseId
                );
            } else {
                console.log("else")
                setIsLoading(false);
                setShowAlert(true);
                handleAddAlert(
                    "Payment Error",
                    "There was an error while processing your payment",
                    "error",
                    true
                );
            }
        } catch (error) {
            console.error("Error in flow:", error);
        }

    }

    const updateUserDetails = async () => {
        if (isAuthenticated && bearerToken && userId) {
            // Ensure all necessary data is available
            const sessionUseEmail = localStorage.getItem("useEmail");
            const sessionUsePhone = localStorage.getItem("usePhone");
            setUseEmail(sessionUseEmail === "true");
            setUsePhone(sessionUsePhone === "true");
            setBearerToken(bearerToken);
            setUserName(userName);
            setUserId(userId);
            await fetchAndUpdateUserData(sessionUseEmail === "true");
        }
    };

    const fetchAndUpdateUserData = async (sessionUseEmail) => {
        if (!bearerToken || !userId) return; // Avoid unnecessary calls

        console.log("Fetching and updating user data...");
        const userInfo = await fetchUserDetails(bearerToken, userId);

        if (userInfo) {
            setPhoneNumber(userInfo?.phone);
            setEmail(userInfo?.email);
            setfirstName(userInfo?.first_name);
            setLastName(userInfo?.last_name);
            const isUserInfoComplete = checkUserInfoExists(
                userInfo?.first_name,
                userInfo?.last_name,
                userInfo?.email,
                userInfo?.phone,
                sessionUseEmail
            );
            setIsUserInfoExists(isUserInfoComplete);
            if (isUserInfoComplete) {
                setEnablePayButton(true);
            }
        }
    };

    const handleClose = () => {

    }

    useEffect(() => { 
    }, [isAuthenticated]);
    useEffect(() => { 
        console.log("page refreshed")
      }, [count]);
    useEffect(() => { }, [email]);
    useEffect(() => {
        updateUserDetails();
    }, [isAuthenticated, bearerToken, userId, userName]);
    return (
        <>
            <MuiDialog
                open={!open && !isPaymentSuccessful}
                onClose={handleClose}
                maxWidth="md"
                fullWidth={true}
                fullScreen={fullScreen}
            >
                {isLoading && <LoadingOverlay message={message} />}
                <section className={styles.card}>
                    <div className={styles.cardholder}>
                        <img className={styles.closebtn} src={close} onClick={onClose} />
                        <div
                            className={styles.leftcontent}
                            style={{
                                display:
                                    (window.innerWidth > 480 || (isCompleted && isAuthenticated))
                                        ? "flex"
                                        : "none",
                            }}
                        >
                            <div className={styles.carddetails}>
                                <div className={styles.imgholder} style={{display: window.innerWidth > 480?"flex":"none"}}>
                                    <img className={styles.eventimage} src={image} />
                                </div>
                                <div className={styles.paymentdetails}>
                                    <h3 className={styles.eventheading}>{details.title}</h3>
                                    <div className={styles.datequantity}>
                                        <div className={styles.ticketdate}>
                                            <h4 className={styles.quantity}>
                                                {details.title} entry ticket -{count} x tickets
                                            </h4>
                                            <p className={styles.subtitle}>
                                                Access to {formatDate(details.event_start_date)}
                                            </p>
                                        </div>
                                        <div>
                                            <p>&#8377; {count * details.event_mrp_price}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className={styles.subtotal}>
                                            Sub Total<span>&#8377; {details.event_mrp_price}</span>
                                        </p>
                                        {isCompleted && (
                                            <p className={styles.subtotal}>
                                                Coupon Discount <span>&#8722;&#8377; {details.event_mrp_price}</span>
                                            </p>
                                        )}
                                        <p className={styles.subtotal}>
                                            GST <span>&#8377; {details.event_mrp_price}</span>
                                        </p>
                                        <p className={styles.total}>
                                            Total <span>&#8377; {details.event_mrp_price}</span>
                                        </p>
                                    </div>
                                    <CongratulationsBanner priceSymbol={"$"} discount={700} />

                                    <img className={styles.ticket} src={ticket} />
                                </div>
                               
                            </div>
                            
                        </div>
                            {isCompleted ? (<button
                                className={styles.ticketbtn}
                                style={{ cursor: "pointer"}}
                                onClick={goBack}
                                id="1"
                            >
                                Change ticket size
                            </button>):<></>}

                        <div className={styles.rightcontent}>
                            <div className={styles.progressbar}>
                                <p className={styles.active}></p>
                                <p
                                    className={`${isCompleted ? styles.active : styles.inactive}`}
                                ></p>
                            </div>
                            {!isCompleted && (
                                <div className={styles.ticketquantity}>
                                    <div onClick={handleClick} className={styles.counterholder}>
                                        <h4 className={styles.entryticket}>Entry Ticket</h4>
                                        <div className={styles.counter}>
                                            <p className={styles.counters}>
                                                <img id="1" src={decrease} />
                                            </p>
                                            <p className={styles.counters}>{count}</p>

                                            <p className={styles.counters}>
                                                <img id="2" src={increase} />
                                            </p>
                                        </div>
                                    </div>
                                    <h5 className={styles.price}>&#8377; {details.event_mrp_price}</h5>
                                    <div>
                                        <h4 className={styles.details}>Details</h4>
                                        <ul className={styles.list}>
                                            <li className={styles.listdetails}>
                                                <img src={tick} />
                                                Grants entry to One Pax
                                            </li>
                                            <li className={styles.listdetails}>
                                                <img src={tick} />
                                                Free Entry for Children below 5 years of age
                                            </li>
                                            <li className={styles.listdetails}>
                                                <img src={tick} />
                                                Price exclusive of GST
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {isCompleted && (
                                <>
                                    <LoginCard
                                        setEnablePayButton={handlePayEnable}
                                        bypass={false}
                                        updateIsUserInfoExists={handleUserInfoExists}
                                        updateBearerToken={handleBearerToken}
                                        isEvent={true}
                                    ></LoginCard>

                                    {(otpValidated || isAuthenticated) && (
                                        <Card isEvent={isEvent}>
                                            <h3>Have a coupon code? Enter here</h3>
                                            <CouponContainer>
                                                <div className={styles.couponHandler}>
                                                    <CouponInput
                                                        type="text"
                                                        placeholder="Enter Coupon Code"
                                                        value={coupon}
                                                        onChange={(event) =>
                                                            handleCouponChange(event, setCoupon)
                                                        }
                                                    />
                                                    <ApplyButton onClick={handleApplyCoupon}>
                                                        Apply
                                                    </ApplyButton>
                                                </div>
                                            </CouponContainer>
                                        </Card>
                                    )}
                                </>
                            )}
                            
                            {isCompleted ? (
                                <div className={`${styles.btnholder} ${styles.fixedfooter}`} >
                                    <button
                                        className={styles.btnback}
                                        style={{ cursor: "pointer"}}
                                        onClick={goBack}
                                        id="1"
                                    >
                                        Back
                                    </button>
                                    {isAuthenticated ?<button
                                        className={styles.btnnext}
                                        onClick={handlePayment}
                                        id="2"
                                    >
                                        Pay
                                    </button>:<></>}
                                </div>
                            ) : (
                                <div className={`${styles.btnholder} ${styles.fixedfooter}`}>
                                    <button className={styles.btnnext} onClick={goNext}>
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* {window.innerWidth <480 ? (<button
                        className={styles}"btn-next"
                        style={{
                            cursor: isAuthenticated ? "pointer" : "not-allowed",
                        }}
                        onClick={handlePayment}
                        id="2"
                    >
                        Pay
                    </button>):<></>} */}
                </section>


            </MuiDialog>
            {isConfirmationDialogOpen && (
                <ConfirmationDialog
                    open={isConfirmationDialogOpen}
                    onClose={handleCloseConfirmationDialog}
                    image={image}
                    title={"title"}
                    description={"description"}
                    {...dialogProps}
                    redirect={"/my-learning"}
                    countdownDuration={8}
                    whatsappLink={"whatsapplink"}
                />
            )}
        </>
    )
}