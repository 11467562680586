import React, { useEffect, useState } from "react";
import "./MyLearning.css";
import { open_api_with_user_token } from "../../Utils/network";
import CustomAlert from "../AlertDialog/CustomAlert";
import { addAlert, removeAlert } from "../Dialog/helper";
import { useNavigate } from "react-router-dom";
import {
  NoPackagesContainer,
  NoPackagesImage,
  NoPackagesText,
} from "../../Components/Dialog/PaymentDialogStyles";
import emptyBox from "../../Assets/Logo_icons/emptybox.svg";
import WhatsAppButton from "../Button/WhatsAppButton";

const ITEMS_PER_PAGE = 12;

const MyLearning = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState(null);
  const [filterOption, setFilterOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [workshops, setWorkshops] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const fetchWorkshops = async (page = 1) => {
    const bearerToken = localStorage.getItem("bearerToken");
    const baseUrl = "/workshop/purchased_list";

    const queryParams = new URLSearchParams();

    queryParams.append("sort_by", sortOption || "recently_purchased");

    if (filterOption) {
      queryParams.append("type", filterOption);
    }

    if (searchQuery) {
      queryParams.append("search", searchQuery);
    }

    queryParams.append("page", page);

    //const url = `${baseUrl}?${queryParams.toString()}`;
    const url = `${baseUrl}${queryParams.toString() ? "?" + queryParams.toString() : ""}`;

    try {
      const response = await open_api_with_user_token(bearerToken).get(url);
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        const fetchedWorkshops = response?.data?.data || [];

        setWorkshops(fetchedWorkshops);

        const maxPages = response?.data?.max_pages || 1;
        setTotalPages(maxPages);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchWorkshops(currentPage);
  }, [sortOption, filterOption, searchQuery, currentPage]);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleWorkshopClick = (
    workshopSlug,
    workshopExperts,
    workshopPurchaseId
  ) => {
    const expertIds = workshopExperts.map((expert) => expert.id);
    navigate(`/my-learning/ondemand/${workshopPurchaseId}/${workshopSlug}`, {
      state: { expertIds },
    });
  };

  const handleJoinNowButtonClick = (live_workshop_url) => {
    if (live_workshop_url) {
      window.open(live_workshop_url, "_blank");
    } else {
      setShowAlert(true);
      handleAddAlert(
        "Live Workshop Not Available",
        "The workshop has not started yet, or the link is not available. If the issue persists, please contact support.",
        "error",
        true
      );
    }
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  return (
    <div className="my-learning-container">
      <div className="my-learning-header">
        <div className="controls">
          <div className="sort-filter">
            <div className="sort-filter-container">
              <p>Sort by:</p>
              <select value={sortOption} onChange={handleSortChange}>
                <option value="recently_purchased">Recently purchased</option>
                <option value="alphabetical">Alphabetical</option>
                <option value="upcoming">Upcoming</option>
                <option value="duration">Duration</option>
              </select>
            </div>
            <div className="sort-filter-container">
              <p>Filter by:</p>
              <select value={filterOption} onChange={handleFilterChange}>
                <option value="">All</option>
                <option value="ondemand">Recorderd</option>
                <option value="live">Live Schedule</option>
              </select>
            </div>
          </div>
          <div className="search">
            <input
              type="text"
              placeholder="Search courses"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>
      {workshops.length === 0 ? (
        <>
          {" "}
          <NoPackagesContainer>
            <NoPackagesImage
              src={emptyBox}
              alt="You have not yet purchased any workshops"
            />
            <NoPackagesText>
              You have not yet purchased any workshops
            </NoPackagesText>
          </NoPackagesContainer>
        </>
      ) : (
        <>
          <div className="cards-container">
            {workshops?.map((item) => {
              const isLiveSchedule =
                item.workshop.type.title.toLowerCase() === "live schedule";
              const currentDateTime = new Date();
              const startDateTime = new Date(
                item?.workshop_batch?.start_date_time
                  ? item?.workshop_batch?.start_date_time
                  : item?.workshop?.start_date_time
              );
              const endDateTime = new Date(
                item?.workshop_batch?.end_date_time
                  ? item?.workshop_batch?.end_date_time
                  : item?.workshop?.end_date_time
              );
              const displayBatchName = item?.workshop_batch?.display_name
                ? item?.workshop_batch?.display_name
                : "";
              const isJoinDisabled =
                isLiveSchedule && endDateTime < currentDateTime;
              const formattedDate = startDateTime.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
              const formattedTime = startDateTime.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              });

              return (
                <div
                  key={item?.id}
                  className="mylearning-card"
                  onClick={() =>
                    handleWorkshopClick(
                      item?.workshop?.slug,
                      item?.workshop?.workshop_experts,
                      item?.id
                    )
                  }
                >
                  <div
                    className={`mylearning-card-chip ${item?.workshop?.type?.title.toLowerCase().replace(/\s+/g, "-")}`}
                  >
                    {item?.workshop?.type?.title === "Live Schedule" ? (
                      <span>Live</span>
                    ) : (
                      <span>Recorded</span>
                    )}
                  </div>
                  <img
                    src={item?.workshop?.file || "/path-to-placeholder-image"}
                    alt={item?.workshop?.title}
                    className="card-image"
                  />
                  <div className="card-content">
                    <div
                      className={`card-category-wrapper ${!isLiveSchedule ? "recorded" : ""}`}
                    >
                      <div className="card-details-mylearning">
                        <h3 className="card-title">{item?.workshop?.title}</h3>

                        <p className="card-expert">
                          by{" "}
                          {item?.workshop?.workshop_experts
                            .map(
                              (expert) =>
                                `${expert.first_name} ${expert.last_name}`
                            )
                            .join(", ")}
                        </p>

                        {isLiveSchedule ? (
                          <>
                            <p className="card-category">
                              Start date: {formattedDate}
                              <br />
                              Start time: {formattedTime}
                              <br />
                              {displayBatchName != ""
                                ? `Batch: ${displayBatchName}`
                                : ""}
                            </p>
                            <button
                              className={`join-button ${isJoinDisabled ? "disabled" : ""}`}
                              disabled={isJoinDisabled}
                              onClick={() =>
                                handleJoinNowButtonClick(
                                  item?.workshop?.live_workshop_url
                                )
                              }
                            >
                              {isJoinDisabled
                                ? "Workshop Ended"
                                : "Join workshop"}
                            </button>
                            {!isJoinDisabled && (
                              <div className="whatsapp-button-workshopcard-wrapper">
                                {item?.workshop?.workshop_batch
                                  ? item?.workshop_batch
                                      ?.whatsapp_group_url && (
                                      <WhatsAppButton
                                        groupLink={
                                          item?.workshop_batch
                                            ?.whatsapp_group_url
                                        }
                                      />
                                    )
                                  : item?.workshop?.whatsapp_group_url && (
                                      <WhatsAppButton
                                        groupLink={
                                          item?.workshop?.whatsapp_group_url
                                        }
                                      />
                                    )}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <button
                              className={`join-button`}
                              disabled={isJoinDisabled}
                              onClick={() =>
                                handleWorkshopClick(
                                  item?.workshop?.slug,
                                  item?.workshop?.workshop_experts
                                )
                              }
                            >
                              watch now
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="pagination">
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index + 1}
                className={currentPage === index + 1 ? "active" : ""}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}

      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </div>
  );
};

export default MyLearning;
