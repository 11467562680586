import { open_api_without_auth } from "./network";

export const handlePhoneNumberChange = (
  value,
  data,
  setIsdCode,
  setPhoneNumber,
  setPhoneNumberError,
  setPhoneWithCountryCode
) => {
  const isd_code = `${data.country.dialCode}`;
  setIsdCode(isd_code);
  setPhoneNumber(value);
  const phoneWithCountryCode = `+${isd_code}${value}`;
  setPhoneWithCountryCode(phoneWithCountryCode);
  if (value) {
    setPhoneNumberError(false);
  }
};

export const requestOTP = async (
  phoneNumber,
  isdCode,
  setOtpRequested,
  setShowAlert,
  addAlert
) => {
  const localData = {
    phone: phoneNumber,
    isd_code: isdCode,
  };

  try {
    const res = await open_api_without_auth.post(
      `/auth/login/generate_otp/`,
      localData
    );
    if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
      setOtpRequested(true);
      setShowAlert(true);
      addAlert(
        "OTP Sent",
        `OTP is sent to +${isdCode} ${phoneNumber}`,
        "success",
        true
      );
      return res?.data;
    }
  } catch (err) {}
};

export const addAlert = (
  title,
  message,
  type,
  autoClose = false,
  setAlerts
) => {
  setAlerts((prevAlerts) => [
    ...prevAlerts,
    { id: Date.now(), title, message, autoClose, type },
  ]);
};

export const removeAlert = (id, setAlerts) => {
  setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
};

export const handleOTPChange = (e, index, otp, setOtp, inputRefs) => {
  const { value } = e.target;
  if (/^[0-9]$/.test(value) || value === "") {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  }
};

export const handleKeyDown = (e, index, otp, inputRefs) => {
  if (e.key === "Backspace" && otp[index] === "" && index > 0) {
    inputRefs.current[index - 1].focus();
  }
};



export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const daySuffix =
    day === 1 || day === 21 || day === 31
      ? "st"
      : day === 2 || day === 22
      ? "nd"
      : day === 3 || day === 23
      ? "rd"
      : "th";
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day}${daySuffix} ${month} ${year}`;
};

export const formatSlotTime = (slotDate, slotTime, duration = "01:00:00") => {
  // Convert duration "HH:MM:SS" to total minutes
  const totalMinutes = getDurationInMins(duration);

  const startDateTime = new Date(`${slotDate}T${slotTime}`);
  const endDateTime = new Date(
    startDateTime.getTime() + totalMinutes * 60 * 1000
  );

  const formattedStart = startDateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedEnd = endDateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${formattedStart} - ${formattedEnd}`;
};

export const getDurationInMins = (duration) => {
  if (!duration) return "N/A";
  const [hours, minutes, seconds] = duration.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes + seconds / 60;
  return totalMinutes;
};

export const formatDateTime = (isoString) => {
  // Remove additional microseconds beyond milliseconds, if present
  const truncatedISOString = isoString.split(".")[0]; // Retain up to seconds, add Z for UTC

  const dateObj = new Date(truncatedISOString); // Convert to Date object

  // Check if date is valid
  if (isNaN(dateObj.getTime())) {
    return { formattedDate: "Invalid Date", formattedTime: "Invalid Time" };
  }

  // Create formatter for IST time zone
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "short", // Use short form of month
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true, // 12-hour format
  };

  // Format date and time based on IST
  const formatter = new Intl.DateTimeFormat("en-GB", options);
  const [
    { value: day },
    ,
    { value: month },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: period },
  ] = formatter.formatToParts(dateObj);

  // Day suffix logic
  const daySuffix =
    day === "1" || day === "21" || day === "31"
      ? "st"
      : day === "2" || day === "22"
      ? "nd"
      : day === "3" || day === "23"
      ? "rd"
      : "th";

  // Construct formatted date
  const formattedDate = `${day}${daySuffix} ${month} ${year}`;

  // Construct formatted time
  const formattedTime = `${hour}:${minute} ${period}`;

  return {
    formattedDate,
    formattedTime,
  };
};

export const formatDuration = (duration) => {
  // Initialize hours and minutes
  let hours = 0;
  let minutes = 0;

  // Regular expressions to extract hours and minutes
  const hoursRegex = /(\d+)\s*hr/; // Matches "1hr" or "2 hrs"
  const minutesRegex = /(\d+)\s*min/; // Matches "15mins" or "30 mins"

  // Extract hours if present
  const hoursMatch = duration.match(hoursRegex);
  if (hoursMatch) {
    hours = parseInt(hoursMatch[1], 10);
  }

  // Extract minutes if present
  const minutesMatch = duration.match(minutesRegex);
  if (minutesMatch) {
    minutes = parseInt(minutesMatch[1], 10);
  }

  // Format hours and minutes to two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Return the formatted duration
  return `${formattedHours}:${formattedMinutes}:00`;
};

// Helper function to format time in "hh:mm AM/PM" format
export const formatTime = (time) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  let period = "am";
  let hour = hours;

  if (hour === 0) {
    hour = 12; // Midnight
  } else if (hour === 12) {
    period = "pm"; // Noon
  } else if (hour > 12) {
    hour -= 12; // Convert 24-hour time to 12-hour time
    period = "pm";
  }

  return `${hour}:${String(minutes).padStart(2, "0")}${period}`;
};

// Function to calculate the end time in ISO format
export const calculateEndTime = (date, time, duration) => {
  const [hours, minutes, seconds] = duration.split(":").map(Number);
  const slotStartTime = new Date(`${date}T${time}`);
  slotStartTime.setHours(slotStartTime.getHours() + hours);
  slotStartTime.setMinutes(slotStartTime.getMinutes() + minutes);
  slotStartTime.setSeconds(slotStartTime.getSeconds() + seconds);

  return slotStartTime.toISOString(); // Return in ISO format
};

export const calculateStartTime = (date, time) => {
  const slotStartTime = new Date(`${date}T${time}`);
  return slotStartTime.toISOString(); // Return in ISO format
};

export const checkIfButtonShouldBeDisabled = (
  date,
  time,
  duration,
  setIsButtonDisabled,
  setIsSessionStarted
) => {
  const slotStartTimeString = `${date}T${time}`;
  const slotStartTime = new Date(slotStartTimeString);

  const [hours, minutes, seconds] = duration.split(":").map(Number);

  const slotEndTime = new Date(slotStartTime.getTime());
  slotEndTime.setHours(slotEndTime.getHours() + hours);
  slotEndTime.setMinutes(slotEndTime.getMinutes() + minutes);
  slotEndTime.setSeconds(slotEndTime.getSeconds() + seconds);

  const enableStartTime = new Date(slotStartTime.getTime());
  enableStartTime.setMinutes(enableStartTime.getMinutes() - 30);

  const disableEndTime = new Date(slotEndTime.getTime());
  disableEndTime.setMinutes(disableEndTime.getMinutes() + 30);

  const currentTime = new Date();

  if (currentTime >= enableStartTime && currentTime <= disableEndTime) {
    setIsButtonDisabled(false); // Enable button
  } else {
    setIsButtonDisabled(true); // Disable button
  }

  if (currentTime >= enableStartTime) {
    setIsSessionStarted(false);
  } else {
    setIsSessionStarted(true);
  }
};
