import React, { useState, useEffect, useRef } from "react";
import styles from "./DateAvailability.module.css";
import noSlot from "../../Assets/course_page/noSlots.png";
import { open_api } from "../../Utils/network";
import leftArrow from "../../Assets/Logo_icons/arrow_back.svg";
import rightArrow from "../../Assets/Logo_icons/forward_arrow.svg";
import TimeAvailability from "./TimeAvailability";
import { PayButton } from "./PaymentDialogStyles";
import notAvailable from "../../Assets/Logo_icons/not_available.svg";
import WhatsAppButton from "../Button/WhatsAppButton";

const DateAvailability = ({
  expertId,
  onDateChange,
  onTimeSlotChange,
  onProceedToPayment,
  onSelectedSlot,
  onDurationChange,
  packageDuration,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const dateListRef = useRef(null);

  useEffect(() => {
    const fetchAvailableDates = async () => {
      try {
        const response = await open_api.get(
          `/session/slot_list_available?expert=${expertId}&duration=${packageDuration}`
        );
        const availableSlots = response?.data?.data || [];
        const available = availableSlots.map((slot) => ({
          date: new Date(slot.date),
          slots: slot.total_slots,
        }));
        setAvailableDates(available);

        if (available.length > 0) {
          setSelectedDate(available[0].date); // Automatically select the first available date
        }
      } catch (error) {
        console.error("Error fetching available dates:", error);
      }
    };
    fetchAvailableDates();
  }, [expertId, packageDuration]);

  useEffect(() => {
    if (!selectedDate) return;

    const fetchTimeSlots = async () => {
      try {
        const selectedDateStr = selectedDate.toISOString().split("T")[0];
        const response = await open_api.get(
          `/session/slot_list?date=${selectedDateStr}&expert=${expertId}&duration=${packageDuration}`
        );
        setTimeSlots(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching time slots:", error);
        setTimeSlots([]);
      }
    };
    fetchTimeSlots();
  }, [selectedDate, expertId, packageDuration]);

  const handleNext = () => {
    if (dateListRef.current) {
      const container = dateListRef.current;
      const scrollAmount = container.clientWidth; // Full width of visible container
      const maxScroll = container.scrollWidth - container.clientWidth;

      // Calculate the next scroll position
      const nextScrollPosition = Math.min(
        container.scrollLeft + scrollAmount,
        maxScroll
      );

      container.scrollTo({
        left: nextScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const handlePrevious = () => {
    if (dateListRef.current) {
      const container = dateListRef.current;
      const scrollAmount = container.clientWidth; // Full width of visible container

      // Calculate the previous scroll position
      const prevScrollPosition = Math.max(
        container.scrollLeft - scrollAmount,
        0
      );

      container.scrollTo({
        left: prevScrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    onDateChange(selectedDate);
  }, [selectedDate, onDateChange]);

  useEffect(() => {
    onTimeSlotChange(selectedTimeSlot);
  }, [selectedTimeSlot, onTimeSlotChange]);

  useEffect(() => {
    onSelectedSlot(selectedSlot);
  }, [selectedSlot, onSelectedSlot]);

  useEffect(() => {
    onDurationChange(selectedDuration);
  }, [selectedDuration, onDurationChange]);

  const groupedTimeSlots = timeSlots.reduce(
    (acc, slot) => {
      const [hours] = slot.time.split(":").map(Number);
      const period = hours < 12 ? "Morning" : hours < 18 ? "Midday" : "Evening";
      acc[period].push(slot);
      return acc;
    },
    { Morning: [], Midday: [], Evening: [] }
  );

  return (
    <div className={styles.container}>
      {availableDates.length === 0 ? (
        <div className={styles.notAvailableContainer}>
          <img
            src={notAvailable}
            alt="No Slots Available"
            style={{ width: "50%", height: "50%" }}
          />
          <div className={styles.noSlotsMessage}>No slots available</div>
          <div className={styles.noSlotsMessageSubtitle}>
            We can help you to connect with this expert. Leave a message on
            WhatsApp at +91 7710048212.
          </div>
          <div>
            <WhatsAppButton isChatOnWhatsapp={true} />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.dateHeading}>
            <h3>When should we meet?</h3>
            <div className={styles.dateHeadingButtons}>
              <button className={styles.scrollButton} onClick={handlePrevious}>
                <img src={leftArrow} alt="Previous" />
              </button>

              <button className={styles.scrollButton} onClick={handleNext}>
                <img src={rightArrow} alt="Next" />
              </button>
            </div>
          </div>

          <div className={styles.dateListWrapper}>
            <div ref={dateListRef} className={styles.dateList}>
              {availableDates.map(({ date, slots }) => (
                <div
                  key={date}
                  className={`${styles.dateItem} ${
                    selectedDate?.toDateString() === date.toDateString()
                      ? styles.selected
                      : ""
                  }`}
                  onClick={() => setSelectedDate(date)}
                >
                  <div className={styles.dateAvialable}>
                    <span className={styles.day}>
                      {date.toDateString().split(" ")[0]}
                    </span>
                    <span className={styles.date}>
                      {date.getDate()}{" "}
                      {date.toLocaleString("default", { month: "short" })}
                    </span>
                    <span
                      className={`${styles.slots} ${
                        slots === 1
                          ? styles.red
                          : slots <= 3
                            ? styles.yellow
                            : styles.green
                      } `}
                    >
                      {slots} {slots === 1 ? "slot" : "slots"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <TimeAvailability
            groupedTimeSlots={groupedTimeSlots}
            selectedTimeSlot={selectedTimeSlot}
            setSelectedTimeSlot={setSelectedTimeSlot}
            setSelectedSlot={setSelectedSlot}
            setSelectedDuration={setSelectedDuration}
          />

          <PayButton
            className={styles.proceedButton}
            disabled={!selectedTimeSlot}
            onClick={onProceedToPayment}
          >
            Proceed to Payment
          </PayButton>
        </>
      )}
    </div>
  );
};

export default DateAvailability;
