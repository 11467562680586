import React, { Children, useEffect, useState } from "react";
import styles from "./header.module.css";
import logo from "../../Assets/Logo_icons/MyBurgo_logo.svg";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import { open_api } from "../../Utils/network";
import { useDialog } from "../Dialog/DialogContext";
import useAuth from "../Hook/useAuth";
import { fetchUserDetails } from "../Dialog/helper";
import { isProduction } from "../../Utils/network";

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const { isAuthenticated, setIsAuthenticated, logout } = useAuth();
  const [userName, setUserName] = useState("");
  const { openLoginDialog, closeLoginDialog } = useDialog();
  const [profileExpanded, setProfileExpanded] = useState(false);
  const [isExpert, setIsExpert] = useState(false);
  const [menuList, setMenuList] = useState([
    {
      id: 1,
      title: "Home",
      url: "/",
      dropDown: false,
      Children: [],
    },
    {
      id: 2,
      title: "Services",
      url: "/services",
      dropDown: false,
      Children: [
        {
          id: 11,
          title: "Child Care",
          url: "/child-care",
        },
        // {
        //   id: 12,
        //   title: "Pregnancy Care",
        //   url: "/pregnancy-care",
        // },
        // {
        //   id: 13,
        //   title: "Mental Health",
        //   url: "/mental-health",
        // },
      ],
    },
    {
      id: 3,
      title: "Experts",
      url: "/experts",
      dropDown: false,
      Children: [],
    },
    {
      id: 4,
      title: "Blogs",
      url: "/blogs",
      dropDown: false,
      Children: [],
    },
    // {
    //   id: 5,
    //   title: "Events",
    //   url: `/events/details/nutrifeista-jan-2025`,
    //   dropDown: false,
    //   Children: [],
    // },
  ]);

  useEffect(() => {}, [isAuthenticated]);

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    const storedUserId = localStorage.getItem("userId");
    const bearerToken = localStorage.getItem("bearerToken");
    const fetchUserData = async () => {
      try {
        const userInfo = await fetchUserDetails(bearerToken, storedUserId);
        const isExpert = userInfo.user_type_str === "Expert";
        setIsExpert(isExpert);
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUserData();
    setUserName(storedUserName);
  }, [userName, isExpert]);

  const handleLoginSuccess = (userName) => {
    setUserName(userName);
    closeLoginDialog();

    window.location.reload();
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handelDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handelDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handelnavigation = (url, pass) => {
    if (url) {
      navigate(url, pass);
    }
    // else {
    //   openLoginDialog();
    // }
    handelDrawerClose();
  };

  const handleBookRemainingNavigation = (url) => {
    if (url) {
      navigate(url, { state: { selectionType: "Session" } });
    }
    handelDrawerClose();
  };

  const getInitials = (name) => {
    // Check if the name is null, undefined, or empty
    if (!name) {
      return ""; // Return an empty string or a fallback value if name is invalid
    }

    // Split the name and get the initials
    const [firstName, lastName] = name.split(" ");
    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";

    return firstInitial + lastInitial;
  };

  const isMenuActive = (path) => {
    // console.log(path, "widu8uy3hek");
    // return location.pathname === path;
  };
  const [expertCatagory, setExpertCatagory] = useState([]);

  useEffect(() => {
    getExpertDetails();
  }, []);
  const getExpertDetails = async () => {
    try {
      const res = await open_api.get(`core/header/expert_type`);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        const expertCategories = res?.data?.results || [];
        setMenuList((prevMenuList) => {
          return prevMenuList.map((item) => {
            if (item.title === "Experts") {
              return {
                ...item,
                Children: expertCategories.map((cat) => ({
                  id: cat?.id,
                  title: cat.title,
                  url: `/experts`,
                  slug: { state: cat },
                })),
              };
            }
            return item;
          });
        });
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/");
    window.location.reload(); // Ensure full app reload to clear any lingering state
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleVisitWebsite = () => {
    if (isProduction) {
      window.open("https://dashboard.myburgo.com", "_blank");
    } else {
      window.open("https://dashboard.dev.myburgo.com", "_blank");
    }
  };

  // console.log(menuList, "updatedMenulist");
  return (
    <>
      <header className={styles.mainHeader}>
        <div className={styles.logo}>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => handelnavigation("/")}
            src={logo}
            alt="logo"
          />
        </div>

        <div className={styles.wrapperNavDiv}>
          <nav className={styles.burgoNavigationBar}>
            <ul className={styles.navBarList}>
              {menuList?.map((v, i) => {
                return (
                  <>
                    {v?.Children.length == 0 ? (
                      <li
                        className={`${styles.navBarList_item} ${
                          isMenuActive(v.url) && styles.activeMenu
                        }`}
                        style={{ cursor: "pointer" }}
                        key={v?.id}
                        onClick={() => handelnavigation(v?.url)}
                      >
                        {v?.title}
                      </li>
                    ) : (
                      <li
                        className={`${styles.navBarList_item} ${
                          isMenuActive(v.url) && styles.activeMenu
                        }`}
                        key={v?.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => handelnavigation()}
                      >
                        {v?.title}{" "}
                        <ExpandMoreIcon className={styles.haveanimation} />
                        <ul className={styles.dropDownList}>
                          {v?.Children?.map((drop, i) => {
                            return (
                              <>
                                <li
                                  onClick={() =>
                                    handelnavigation(drop?.url, drop?.slug)
                                  }
                                >
                                  {drop?.title}
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        {/* <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {v?.Children?.map((drop, i) => {
                          return (
                            <>
                            <MenuItem onClick={handleClose}>{drop?.title}</MenuItem>
                              
                            </>
                          );
                        })}
                      </Menu> */}
                      </li>
                    )}
                  </>
                );
              })}
              {isAuthenticated && (
                <li
                  className={`${styles.navBarList_item} ${
                    isMenuActive("/my-learning") && styles.activeMenu
                  }`}
                  style={{ cursor: "pointer" }}
                  key="my-learning"
                  onClick={() => handelnavigation("/my-learning")}
                >
                  My Courses
                </li>
              )}
            </ul>
          </nav>

          {isAuthenticated ? (
            <div className={styles.profileSection}>
              <div className={styles.profileIcon}>
                <span onClick={() => handelnavigation("/profile")}>
                  {getInitials(userName)}
                </span>{" "}
                {/* Initial of the user */}
              </div>
            </div>
          ) : (
            <div className={styles.buttonDiv}>
              <button
                className={styles.loginButton}
                onClick={() => handleLoginClick()}
              >
                Login
              </button>
            </div>
          )}
        </div>

        {/* <div><Button variant="contained">Download App</Button></div> */}
        <div className={styles.MobileNav}>
          <MenuIcon onClick={handelDrawerOpen} />
        </div>
        <Drawer anchor="right" open={drawerOpen} onClose={handelDrawerClose}>
          <Box>
            <div className={styles.mobilenavTop}>
              <div className={styles.logo}>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => handelnavigation()}
                  src={logo}
                  alt="logo"
                />
              </div>
              <CloseIcon onClick={handelDrawerClose} />
            </div>
            <ul className={styles.mobilelist}>
              {menuList?.map((list, i) => {
                const isActive = openDropdown === i;
                return (
                  <li key={list.id}>
                    {list?.Children?.length > 0 ? (
                      <>
                        <div
                          className={`${styles.mobileListItem} ${isActive ? styles.active : ""}`}
                          onClick={() => {
                            setProfileExpanded(false); // Close profile if another dropdown is clicked
                            toggleDropdown(i);
                          }}
                        >
                          {list.title}
                          <ExpandMoreIcon
                            className={styles.mobanimation}
                            style={{
                              transform: isActive
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            }}
                          />
                        </div>
                        {isActive && (
                          <ul
                            className={`${styles.mobileinnerList} ${isActive ? styles.innerlistActive : ""}`}
                          >
                            {list?.Children?.map((v) => (
                              <li
                                key={v.id}
                                className={styles.mobileinnerList_item}
                                onClick={() => {
                                  handelDrawerClose();
                                  handelnavigation(v?.url, v?.slug);
                                }}
                              >
                                {v.title}
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    ) : (
                      <div
                        className={`${styles.mobileListItem}`}
                        onClick={() => {
                          setProfileExpanded(false); // Close profile if another item is clicked
                          handelDrawerClose();
                          handelnavigation(list?.url);
                        }}
                      >
                        {list.title}
                      </div>
                    )}
                  </li>
                );
              })}
              {isAuthenticated && (
                <li
                  className={`${styles.mobileListItem} ${
                    isMenuActive("/my-learning") && styles.activeMenu
                  }`}
                  style={{ cursor: "pointer" }}
                  key="my-learning"
                  onClick={() => handelnavigation("/my-learning")}
                >
                  My Learning
                </li>
              )}
              {/* Profile Section */}
              {isAuthenticated && (
                <li>
                  <div
                    className={`${styles.mobileListItem}`}
                    onClick={() => {
                      toggleDropdown(null); // Close other dropdowns when Profile is clicked
                      setProfileExpanded(!profileExpanded);
                    }}
                  >
                    Profile
                    <ExpandMoreIcon
                      className={styles.mobanimation}
                      style={{
                        transform: profileExpanded
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </div>
                  {profileExpanded && (
                    <ul
                      className={`${styles.mobileinnerList} ${profileExpanded ? styles.innerlistActive : ""}`}
                    >
                      <li
                        className={styles.mobileinnerList_item}
                        onClick={() => {
                          setProfileExpanded(false);
                          handelnavigation("/profile/account");
                        }}
                      >
                        Account
                      </li>
                      <li
                        className={styles.mobileinnerList_item}
                        onClick={() => {
                          setProfileExpanded(false);
                          handelnavigation("/profile/orders");
                        }}
                      >
                        Orders
                      </li>
                      {isExpert ? (
                        <>
                          <li
                            className={styles.mobileinnerList_item}
                            onClick={() => {
                              setProfileExpanded(false);
                              handelnavigation("/profile/revenue");
                            }}
                          >
                            Revenue
                          </li>
                          <li
                            className={styles.mobileinnerList_item}
                            onClick={() => {
                              setProfileExpanded(false);
                              handleVisitWebsite();
                            }}
                          >
                            Dashboard
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      <li
                        className={styles.mobileinnerList_item}
                        onClick={() => {
                          setProfileExpanded(false);
                          handelnavigation("/profile/sessions/upcoming");
                        }}
                      >
                        Upcoming Sessions
                      </li>
                      <li
                        className={styles.mobileinnerList_item}
                        onClick={() => {
                          setProfileExpanded(false);
                          handleBookRemainingNavigation("/profile/orders");
                        }}
                      >
                        Book remaining sessions
                      </li>
                      <li
                        className={styles.mobileinnerList_item}
                        onClick={() => {
                          setProfileExpanded(false);
                          handelnavigation("/delete_account");
                        }}
                      >
                        Delete account
                      </li>
                      <li
                        className={styles.mobileinnerList_item}
                        onClick={() => {
                          setProfileExpanded(false);
                          handleLogout();
                        }}
                      >
                        Logout
                      </li>
                    </ul>
                  )}
                </li>
              )}
            </ul>

            {!isAuthenticated && (
              <div className={`${styles.sideNavButtonDiv}`}>
                <button
                  className={styles.loginButton}
                  onClick={() => handleLoginClick()}
                >
                  Login
                </button>
              </div>
            )}
          </Box>
        </Drawer>
      </header>
    </>
  );
}

export default Header;
