import React, { useState } from "react";
import styled from "styled-components";

const FAQContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    max-width: 600px;
  }
`;

const FAQItem = styled.div`
  margin-top: 10px;
  border: 2px solid ${({ isOpen }) => (isOpen ? "#7A53FF" : "transparent")};
  border-radius: 5px;
  transition: border-color 0.3s;
`;

const Question = styled.div`
  background-color: ${({ isOpen }) => (isOpen ? "#7A53FF" : "#f6f6f6")};
  color: ${({ isOpen }) => (isOpen ? "white" : "black")};
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
  font-family:Poppins;
  position: relative;
  transition:
    background-color 0.3s,
    color 0.3s;

  &:hover {
    background-color: ${({ isOpen }) => (isOpen ? "#7A53FF" : "#e0dbff")};
  }
`;

const Answer = styled.div`
  padding: 15px;
  border-radius: 0 0 5px 5px;
  color: black;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  border-top: 2px solid #7a53ff;
`;

const Chevron = styled.span`
  position: absolute;
  right: 20px;
  transition: transform 0.3s;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

const FAQ = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(0);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How does yoga affect the reproductive system?",
      answer:
        "Yoga helps by improving blood flow, balancing hormones, and reducing stress, which can positively influence the reproductive system.",
    },
    {
      question: "What is the success rate of yoga?",
      answer:
        "The success rate varies depending on the goal, consistency, and other factors, but many people experience significant health improvements.",
    },
    // Add more FAQ items here
  ];

  return (
    <FAQContainer>
      {data.map((faq, index) => (
        <FAQItem key={faq.id} isOpen={openIndex === index}>
          <Question
            isOpen={openIndex === index}
            onClick={() => handleToggle(index)}
          >
            {faq.title || faq.question}
            <Chevron isOpen={openIndex === index}>⌃</Chevron>
          </Question>
          <Answer isOpen={openIndex === index}>
            {Array.isArray(faq.answer)?(faq.answer.map((ans) => (
              <p key={ans.id}>{ans.answer}</p>
            ))):(<p>{faq.answer}</p>)}
          </Answer>
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQ;
