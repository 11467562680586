// src/components/Dialog/PaymentDialogStyles.js
import styled from "styled-components";

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  overflow: hidden;
  max-width: 900px;
  margin: auto;

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: 80vh; /* Adjust height as needed */
  overflow-y: auto;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  h1 {
    color: #602ef4;
    font-size: 26px;
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 0;
  }

  h2 {
    margin-top: 3px;
    margin-bottom: 0;
    font-size: 13px;
    color: #bababa;
    font-weight: 500;
    font-family: "Poppins";
  }

  p {
    margin: 8px 0;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    display: none;
  }
`;

export const RightSection = styled.div`
  background: #e7e7e7;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: 80vh;
  overflow-y: auto;

  h1 {
    color: black;
    font-size: 25px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
  }

  h2 {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 12px;
    color: black;
    font-weight: 300;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    padding: 16px;
    margin-bottom: 50px;
    max-height: calc(100vh - 50px);
  }
`;

export const Card = styled.div`
  background: #fff;
  padding: 0 16px 16px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: ${(props) => (props.isEvent ? "100%" : "400px")};
  margin-top:${(props) => (props.isEvent ? "20px" : "0")};
  box-sizing: border-box;

  h3 {
    font-size: 16px;
    font-weight: 600;
  }

  p {
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    width: 100%;
    max-width: none;
    h3 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
`;

export const Button = styled.button`
  background: #ff67a1;
  color: #fff;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-top: 16px;
  width: 100%;
  font-weight: 700;

  &:hover {
    background: #ff79a5;
  }
`;

export const PayButton = styled.button`
  background: ${(props) => (props.disabled ? "#ccc" : "#ff67a1")};
  color: #fff;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  text-align: center;
  margin-top: 16px;
  width: 100%;
  font-weight: 700;

  &:hover {
    background: ${(props) => (props.disabled ? "#ccc" : "#ff79a5")};
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 1px;
    border-radius: 0;
    z-index: 1000;
    padding: 20px;
  }
`;

export const LoginButton = styled.button`
  background: #ff67a1;
  color: #fff;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-top: 16px;
  width: 100%;
  font-weight: 700;

  &:hover {
    background: #ff79a5;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    ${(props) =>
      !props.bypass &&
      `
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin: 1px;
      border-radius: 0;
      z-index: 1000;
      padding: 20px;
    `}
  }
`;

export const OtpContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0;
  width: 100%;
`;

export const PhoneNumberInput = styled.input`
  border: none;
  outline: none;
  padding: 8px;
  flex: 1;
  border-radius: 0 8px 8px 0;
  overflow: auto;
`;

export const PhoneInput = styled.input`
  border: none;
  outline: none;
  padding: 8px;
  flex: 1;
  border-radius: 0 8px 8px 0;
  overflow: auto;
`;

export const OtpInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  input {
    width: 48px;
    height: 48px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    input {
      width: 40px;
      height: 40px;
    }
  }
`;

export const ResendOtpLink = styled.a`
  color: #1a73e8;
  text-align: center;
  display: block;
  margin-top: 16px;
  cursor: pointer;
`;

export const ServiceCard = styled(Card)`
  display: flex;
  flex-direction: column;

  .service-details {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-end;
  }

  .service-info {
    display: flex;
    flex-direction: column;
  }

  .service-price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .original-price {
    text-decoration: line-through;
    color: gray;
    margin-right: 8px;
  }

  .discounted-price {
    font-weight: bold;
    color: black;
  }

  .divider {
    border-top: 1px solid #ddd;
    margin: 8px 0;
  }

  p {
    text-align: start;
  }
`;

export const AmountCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .amount-left {
    display: flex;
  }

  .amount-right {
    display: flex;
  }

  h4 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

export const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  background: #e0e7ff;
  color: #000;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: 10px;

  svg {
    margin-right: 8px;
  }

  h3 {
    margin: 0;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  &:focus {
    border-color: #6366f1;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
  }
`;

export const InputField = styled.input`
  width: 93%;
  padding: 10px;
  margin-bottom: 2px;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  margin-top: 8px;
`;

export const ErrorText = styled.label`
  color: red;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500;
  margin-top: 0;
`;

export const CouponInput = styled.input`
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 8px;
  flex: 0 0 70%;
`;

export const ApplyButton = styled.button`
  padding: 12px;
  flex: 0 0 20%; /* Flex-basis 20% */
  border: none;
  border-radius: 8px;
  background-color: #8661f5;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #6d49e2;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    width: 100%;
  }
`;

export const CouponContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 8px;
  flex-direction: column;

  .couponHandler {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    flex-direction: column;
    align-items: normal;
  }
`;

export const NoPackagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  margin-top: 20px;
`;

export const NoPackagesImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const NoPackagesText = styled.p`
  margin-top: 20px;
  font-size: 1.2em;
  color: #333;
`;

export const PhoneInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    border: none;
    outline: none;
    padding: 8px 8px 8px 50px; /* Adjust padding to leave space for country code */
    flex: 1;
    border-radius: 0 8px 8px 0;
    overflow: auto;
    width: 100%;
  }

  .flag-dropdown {
    pointer-events: none; /* Disable interaction with the dropdown */
  }
`;

export const CountryCode = styled.span`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #000;
`;
