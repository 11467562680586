import React, { useEffect, useState } from "react";
import Workshop from "../../Components/Workshop/Workshop";
import { useLocation, useParams } from "react-router-dom";
import { open_api_with_user_token } from "../../Utils/network";
import LoadingOverlay from "../../Components/Dialog/LoadingOverlay";
import ExpertSection from "../../Components/ExpertSection/ExpertSection";
import "./WorkshopPage.css";
import DownloadeApp from "../../Components/DownloadeApp/DownloadeApp";

const WorkshopPage = () => {
  const location = useLocation();
  const [expertIds, setExpertIds] = useState(() => {
    return (
      location.state?.expertIds ||
      JSON.parse(localStorage.getItem("expertIds")) ||
      []
    );
  });
  const [curriculumData, setCurriculumData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { workshopSlug, workshopPurchaseId } = useParams();

  useEffect(() => {
    fetchCurriculumData();
  }, []);

  const fetchCurriculumData = async () => {
    try {
      if (location.state?.expertIds) {
        localStorage.setItem(
          "expertIds",
          JSON.stringify(location.state.expertIds)
        );
      }
      const localData = {
        workshopPurchaseId: workshopPurchaseId,
      };
      const bearerToken = localStorage.getItem("bearerToken");
      const response = await open_api_with_user_token(bearerToken).get(
        `/workshop/purchased_workshop/${workshopSlug}/curriculum/`,
        { params: localData }
      );
      setCurriculumData(response?.data?.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (loading) {
    return <LoadingOverlay />;
  }
  return (
    <>
      <section>
        <Workshop
          curriculumData={curriculumData}
          expertIds={expertIds}
          workshopPurchaseId={workshopPurchaseId}
        />
      </section>

      <section className={"expertBackground"}>
        <ExpertSection expertIds={expertIds} />
      </section>

      {/* <section style={{ marginTop: "5%" }}>
        <DownloadeApp />
      </section> */}
    </>
  );
};

export default WorkshopPage;
